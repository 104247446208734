<template>
  <div>
    <Carousel
      :settings="settings"
      :breakpoints="breakpoints"
      :autoplay="8000"
      :transition="600"
      :wrap-around="true"
    >
      <Slide
        v-for="image in images"
        :key="image"
      >
        <div class="slider__item relative">
          <img
            class="slider__hero"
            alt="{{ image.title }}"
            :src="image.fullWidthX1"
            :srcset=" getSrcset(image)"
          >
          <div class="slider__details absolute z-10 text-white top-[150px] w-full">
            <div class="max-w-7xl m-auto px-4 lg:px-12 w-full">
              <p
                v-if="image.minorHeading"
                class="slider__text-minor"
              >
                {{ image.minorHeading }} &nbsp;
              </p>
              <p
                v-if="image.majorHeading"
                class="slider__text-major text-5xl pb-4 text-left"
                v-html="image.majorHeading"
              />
              <a
                v-if="image.page"
                class="slider__button"
                :href="'/' + image.page"
              >
                Learn more
              </a>
              <a
                href="/"
                style="outline: none;"
              >
                <img
                  class="slider__logo w-[190px]"
                  :src="logo"
                >
              </a>
            </div>
          </div>
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
  import 'vue3-carousel/dist/carousel.css';
  import { defineComponent } from 'vue';
  import { Carousel, Slide } from 'vue3-carousel';

  export default defineComponent({
    name: 'Vue3Carousel',
    components: {
      Carousel,
      Slide,
    },
    props: {
      images: {
        type: Array,
        default: () => [
        ],
      },
      logo: {
        type: String,
        default: () => '/dist/logo.png',
      }
    },

    data: () => ({
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 1,
          snapAlign: 'start',
        },
      },
    }),
    methods: {
      getSrcset(image) {
        return `${image.fullWidthX1} 1x, ${image.fullWidthX2} 2x`
      },
    }
  });
</script>

<style>
/* stylelint-disable selector-class-pattern */
:root {
  --vc-clr-primary: #748240;
  --vc-clr-secondary: #e5dfb9;
  --vc-pgn-height: 10px;
  --vc-pgn-width: 10px;
}

.slider__text-major {
  letter-spacing: -0.025em;
  text-shadow: 2px 2px 0.75em #999;
  font-weight: bold;
}

/* .carousel__track{
  margin-left:-250px!important;
} */

/* .carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

.carousel__pagination-button {
  padding: 0;
} */

.banner-carousel-container .slider__item,
.banner-carousel-container .slider__item .slider__hero {
  width: 100%;
}
</style>
