import { createApp } from 'vue';
import VueCarouselHome from '@/vue/VueCarouselHome.vue';
import VueCarouselBanner from '@/vue/VueCarouselBanner.vue';

// App main
const main = async () => {
    // Create our vue instance
    const bannerApp = createApp({
      components: {
        VueCarouselBanner
      }
    });

    // Mount the app
    const bannerAppMounted = bannerApp.mount('.banner-carousel-container');

    // Create our vue instance
    const homeApp = createApp({
      components: {
        VueCarouselHome
      }
    });

    // Mount the app
    const homeAppMounted = homeApp.mount('.carousel-container');

    return Promise.all([
      bannerAppMounted,
      homeAppMounted,
    ])
};

// Execute async function
main().then(() => {
  if (/localhost/.test(window.location.host)) console.log('loaded carousel.ts');
});
