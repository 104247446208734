<template>
  <div>
    <Carousel
      :settings="settings"
      :breakpoints="breakpoints"
      :autoplay="4000"
      :transition="600"
      :wrap-around="true"
    >
      <Slide
        v-for="entry in entries"
        :key="entry"
      >
        <div class="flex flex-row ml-10">
          <div>
            <img :src="entry.img">
          </div>
          <div class="px-8 text-left">
            <p class="text-white font-medium pt-2 pb-4">
              {{ entry.title }}
            </p>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="entry.blurb" />
            <a
              class="news-item__view-story bg-green-900 hover:bg-green-800 text-white p-2 text-sm"
              :href="entry.url"
            >Read more</a>
          </div>
        </div>
      </Slide>
      <template #addons>
        <navigation />
        <pagination />
      </template>
    </Carousel>
  </div>
</template>

<script>
  import 'vue3-carousel/dist/carousel.css';
  import { defineComponent } from 'vue';
  import { Carousel, Slide, Navigation, Pagination } from 'vue3-carousel';

  export default defineComponent({
    name: 'Vue3Carousel',
    components: {
      Carousel,
      Slide,
      Navigation,
      Pagination,
    },
    props: {
      entries: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      breakpoints: {
        // 700px and up
        640: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // 1024 and up
        768: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
      },
    }),
    mounted() {
      console.log('mounted VueCarouselHome');
    },
  });
</script>

<style>
/* stylelint-disable selector-class-pattern */
:root {
  --vc-clr-primary: #748240;
  --vc-clr-secondary: #e5dfb9;
  --vc-pgn-height: 10px;
  --vc-pgn-width: 10px;
}

.carousel-container--home .carousel__track {
  align-items: flex-start;
}

.carousel-container--home .carousel__track li:nth-child(even) {
  background: #8abc59;
  border-top: 8px solid #6dab34;
  border-bottom: 8px solid #6dab34;
}

.carousel-container--home .carousel__track li:nth-child(odd) {
  background: #a3c967;
  border-top: 8px solid #8cbb45;
  border-bottom: 8px solid #8cbb45;
}

.carousel-container--home .carousel img {
  height: 234px;
  object-fit: cover;
}
</style>
